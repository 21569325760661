import { getRegion } from '@/utils/utils'
import env from '../../env'
/**
 * 处理请求Url
 * @param {*} route 路由
 */
const mergeBaseUrl = (route) => {
  console.log('ENV:', env)

  if (route.startsWith('http')) {
    return route
  }

  let baseUrl =
    window.location.host === 'h5.uchoice.pro'
      ? (getRegion() === 'vn' ? 'https://api.vn.uchoice.pro/' : 'https://api.th.uchoice.pro/')
      : 'https://api.ypkshop.com/'

  if (env === 'dev') {
    baseUrl = getRegion() === 'vn' ? 'https://api.dev.vn.youpik.dev/' : 'https://api.dev.th.youpik.dev/'
  }

  if (env === 'test') {
    // baseUrl = 'https://api.test.youpik.dev/'
    window.location.pathname == '/pages/lazadaVoucher/index'
      ? (baseUrl = 'https://youpik.test.catike.com/')
      : (baseUrl = getRegion() === 'vn' ? 'https://api.test.vn.youpik.dev/' : 'https://api.test.th.youpik.dev/')
  }

  if (env === 'pre') {
    baseUrl = getRegion() === 'vn' ? 'https://api.pre.vn.uchoice.pro' : 'https://api.pre.th.uchoice.pro'
  }

  let fullUrl = baseUrl + route
  return fullUrl
}

export default mergeBaseUrl
